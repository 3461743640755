<template>
	<ejs-grid
		height="100%"
		class="dm-flex-1"
		ref="grdTableList"
		:allowExcelExport="true"
		:allowPdfExport="true"
        :allowPaging="allowPaging"
		:allowSorting="true"
		:allowTextWrap="true"
		:dataSource="table.grdTableItems"
		:recordClick="recordOnClick"
		:commandClick="table.grdTableActionOnClick"
		:sortSettings="table.grdTableSortBy"
        :pageSettings="pageSettings"
		:textWrapSettings="{ wrapMode: 'Header' }"
		:toolbar="table.grdTableToolbar"
		:toolbarClick="toolbarClick"
	>
		<e-columns>
			<!-- <e-column
				v-for="field in table.grdTableFields"
				v-if="field.type === 'template'"
				:field="field.key"
				:template="field.template"
				:width="field.width"
				:customAttributes="field.classes"
			/>
			<e-column
				v-for="field in table.grdTableFields"
				v-if="field.type !== 'template'"
				:field="field.key"
				:headerText="field.label"
				:type="field.type"
				:format="formatColumn(field)"
				:allowSorting="true"
				:commands="field.commands"
				:width="field.width"
				:customAttributes="field.classes"
			/> -->
			<e-column
				v-for="(field, index) in table.grdTableFields"
				:key="index"
				:field="field.key"
				:headerText="field.label"
				:template="field.template"
				:type="field.type"
				:format="formatColumn(field)"
				:formatter="field.formatter"
				:allowSorting="field.allowSorting === undefined ? true : field.allowSorting"
				:commands="field.commands"
				:width="field.width"
				:customAttributes="field.classes"
				:disableHtmlEncode="false"
				:textAlign="field.textAlign"
			/>
		</e-columns>
		<e-aggregates>
			<e-aggregate>
				<e-columns>
					<e-column
						v-for="(field, index) in table.grdTableFields"
						:key="index"
						v-if="field.aggregate"
						:columnName="field.key"
						type="Custom"
						:customAggregate="field.aggregateColumn.doAggregation"
						:footerTemplate="footerTemplate"
						:customAttributes="aggregateClasses"
					/>
				</e-columns>
			</e-aggregate>
		</e-aggregates>
	</ejs-grid>
</template>

<script>
    import Vue from "vue";
    import { Page, Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar } from "@syncfusion/ej2-vue-grids";

    export default {
        name: "dm-table",
        props: {
            table: Object,
            allowPaging: {
                default: false,
                type: Boolean,
            },
            pageSettings: {
                default: () => ({ pageCount: 5, pageSize: 50 }),
                type: Object,
            },
        },
        provide: {
            grid: [Page, Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar],
        },
        data: function() {
            return {
                footerTemplate: function(args) {
                    return {
                        template: Vue.component("footerTemplate", {
                            template: `<span class="dm-text-right">{{ data.Custom }}</span>`,
                            data() {
                                return { data: {} };
                            },
                        }),
                    };
                },
                aggregateClasses: {
                    class: "dm-text-right",
                },
            };
        },
        methods: {
            formatColumn(field) {
                if (field.type === "date") {
                    return { type: "date", format: "MM/dd/yyyy" };
                }

                if (field.type === "time") {
                    return { type: "dateTime", format: "hh:mm a" };
                }

                if (field.type === "custom") {
                    return field.format;
                }
            },
            recordOnClick(data) {
                if (!data.column.commands || data.column.commands.length === 0) {
                    this.table.grdTableRowOnClick(data);
                }
            },
            toolbarClick: function(args) {
                switch (args.item.text) {
                    case "PDF Export":
                        this.$refs.grdTableList.pdfExport();
                        break;
                    case "Excel Export":
                        this.$refs.grdTableList.excelExport();
                        break;
                    case "CSV Export":
                        this.$refs.grdTableList.csvExport();
                        break;
                }
            },
        },
        created() {},
        watch: {},
        mounted() {},
    };
</script>
