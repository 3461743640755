<template>
  <div class="dm-relative">
    <div class="datetime-overlay bg-charcoal transparent-80 text-white text-center"
         style="min-width:115px">
      <span>{{ props.dataTransmission?.chartDate || '-' }}</span>
    </div>
    <div class="overlay-wrapper">
      <frost-vision-overlay :computer-vision="computerVision" />
      <div v-if="!offline"
           class="sensor-details-overlay bg-charcoal transparent-80">
        <span class="no-wrap">
          Air:
          <span class="dm-bold mr-2">{{
        airTemp || '--.-' }}{{ temparatureUnit || '°-' }} </span>
        </span>
        <span class="no-wrap">
          Sur:
          <span class="dm-bold mr-2">{{
        surfaceTemp || '--.-' }}{{ temparatureUnit || '°-' }}</span>
        </span>
        <span class="no-wrap">
          H:
          <span class="dm-bold mr-2">{{
        humidity || '--' }}%</span>
        </span>
        <span class="no-wrap">
          D: <span class="dm-bold mr-2">{{
        dewPoint || '--.-' }}{{ temparatureUnit || '°-' }}</span>
        </span>
        <span class="no-wrap">
          W:
          <span class="dm-bold mr-2">
            {{ windSpeed || '--.-' }}{{ windUnit }} {{ windDirection }}
          </span>
        </span>
      </div>
      <div v-else
           class="sensor-details-overlay bg-charcoal transparent-80">
        <span class="no-wrap">
          Sensor Offline
        </span>
      </div>
    </div>
    <fallback-image :images="combinedImages"
                    class="selected-image"
                    height="500"
                    width="808" />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import frostVisionOverlay from './frostVisionOverlay.vue'
import fallbackImage from './fallbackImageComponent.vue'
import { convertWindSpeed, formatWindDirection, parseWindSpeedUnit } from '../js/utils'

const props = defineProps({
  dataTransmission: Object,
  currentCondition: Object,
  temparatureUnit: String,
  isMetric: Boolean,
  alt: String,
  images: {
    type: Array,
    default: () => [],
  },
  offline: {
    type: Boolean,
    default: false,
  },
})

const airTemp = computed(() => props.dataTransmission?.AirTemp)
const surfaceTemp = computed(() => props.dataTransmission?.SurfaceTemp)
const humidity = computed(() => props.dataTransmission?.Humidity)
const dewPoint = computed(() => props.dataTransmission?.DewPoint)

const windSpeed = computed(() => {
  if (props.dataTransmission?.WindSpeed) {
    return convertWindSpeed(props.dataTransmission?.WindSpeed, props.isMetric)
  } else {
    return convertWindSpeed(props.currentCondition?.WindSpeed, props.isMetric)
  }
})
const windDirection = computed(() => {
  if (props.dataTransmission?.WindDirection) {
    return formatWindDirection(props.dataTransmission?.WindDirection)
  } else {
    return formatWindDirection(props.currentCondition?.WindDirection)
  }
})
const windUnit = computed(() => parseWindSpeedUnit(props.isMetric))

const computerVision = computed(() => props.dataTransmission?.ComputerVision)
const combinedImages = computed(() => [...props.images, props.dataTransmission?.ProcessedCameraImageURL].filter(Boolean))
</script>