<template>
  <div class="dm-pointer flex-column" @click="openSensor">
    <h3 class="device-title text-blue underline-dotted">{{ location.Name }}</h3>
    <forecast-icons :forecast="forecast" :is-metric="isMetric" :time-zone="location?.TimeZone"></forecast-icons>
    <div class="preview-image-wrapper">
      <image-with-overlay :data-transmission="mostRecentTransmission" :current-condition="forecast?.CurrentCondition"
        :images="displayedImages" :alt="location.Name + ' preview'" :temparature-unit="temparatureunit"
        :is-metric="isMetric" :offline="location.IsOffline" />
    </div>
    <div style="width:100%; height: 150px; position: relative;">
      <sensor-chart-component :key="showGridView" :sensor="location" chartHeight="150px"
        :data-transmissions="dataTransmissions || []" :most-recent-transmission="mostRecentTransmission"
        :road-conditions-history="forecast?.RoadConditionsHistory || []"
        :forecast-data="forecast?.ForecastData || []" />
    </div>
    <div v-if="isUserSuperAdmin" class="dm-text-center">
      <span class="dm-padding-right-md">Sensor: {{ location.SensorSerialNumber }}</span>
      <div class="forecast-legend">
        <input type="checkbox" class="forecast-icon" disabled="true" />
        <div class="forecast-label"><b>Forecast</b></div>
        <ejs-tooltip v-if="!isTablet" class="tooltip-cont" opensOn='Auto'
          content="Forecast may take several days to generate initially, tuning is ongoing. Road conditions are projected assuming no treatment.">
          <i class="fa fa-solid fa-info-circle"></i>
        </ejs-tooltip>
        <ejs-tooltip v-if="isTablet" class="tooltip-cont-mobile" opensOn='custom' :ref="'tooltip-' + location.ID"
          content="Forecast may take several days to generate initially, tuning is ongoing. Road conditions are projected assuming no treatment.">
          <span @click="(e) => onCustomOpen($refs['tooltip-' + location.ID], e)"
            class="fa fa-solid fa-info-circle"></span>
        </ejs-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapStores } from "pinia";
import { useBaseStore } from "../js/store";

import SidebarNotification from "./notifications/SidebarNotification";
import SensorChartComponent from "./sensorChartComponent";
import SensorDetails from './sensorDetails';
import forecastIcons
  from "./forecastIcons";

import FallbackImageComponent from "./fallbackImageComponent";

import { convertWindSpeed, formatWindDirection, isTabletScreen, parseWindSpeedUnit } from "../js/utils";
import FrostVisionOverlay from "./frostVisionOverlay.vue";
import imageWithOverlay from "./imageWithOverlay.vue";

export default {
  name: "grid-sensor-item",
  props: [
    "location",
    "images",
    "dataTransmissions",
    "mostRecentTransmission",
    "forecast",
    "showGridView"
  ],
  components: {
    SensorChartComponent,
    SidebarNotification,
    forecastIcons,
    FallbackImageComponent,
    SensorDetails,
    FrostVisionOverlay,
    imageWithOverlay,
  },
  data: function () {
    return {
      storeLocations: [],
      loading: false,
    };
  },
  methods: {
    convertWindSpeed,
    formatWindDirection,
    parseWindSpeedUnit,
    openSensor() {
      this.$router.push({ query: { ...this.$route.query, sensorId: this.location.ID } })
    },
    openNotifs() {
      return [];
    },
    openConnectionIssueModal() {
      DM.alertShow(
        "We are experiencing difficulty connecting to this RWIS device. This difficulty is usually intermittent. If it persists, please contact Frost Solutions."
      );
    },
    onCustomOpen(refs, args) {
      const target = args.target.closest('.tooltip-cont-mobile');
      if (target.getAttribute('data-tooltip-id')) {
        refs[0].close();
      } else {
        eventBus.$emit('removeForecastTooltip');
        refs[0].open(target);
      }
    },
  },
  computed: {
    ...mapStores(useBaseStore),
    isUserSuperAdmin() {
      return this.baseStore.user?.IsSuperAdmin
    },
    isMobile() {
      return window.innerWidth < 666
    },
    isTablet() {
      return isTabletScreen(true)
    },
    displayedImages() {
      const urls = this.images ?? [];
      return [...urls, this.mostRecentTransmission?.ProcessedCameraImageURL].filter(Boolean)
    },
    temparatureunit() {
      return this.location?.GroupEmbedded?.TemperatureUnits == "Fahrenheit" ? "°F" : "°C";
    },
    isMetric() {
      return this.location?.GroupEmbedded?.IsMetric;
    },
  },
  mounted() {
    eventBus.$on('removeForecastTooltip', () => {
      Object.values(this.$refs).forEach((ref) => {
        ref?.forEach((tooltip) => {
          if (typeof tooltip.close === 'function') {
            tooltip.close();
          }
        })
      });
    })
  },
  beforeDestroy() {
    eventBus.$off('removeForecastTooltip');
  }
};
</script>
