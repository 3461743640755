<template>
	<div v-observe-visibility="{
				callback: displayChart,
				once: true,
			}"
			 :style="{ height: chartHeight }"
			 style="width:100%; "
			 :key="'sensor-' + sensor.ID">
		<ejs-chart border="{color: '#FFF'}"
							 ref="chartJS"
							 style="overflow:visible;"
							 :style="chartMobileStyle"
							 :height="chartHeight"
							 :id="'chart-' + sensor.ID"
							 :primaryXAxis="primaryXAxis"
							 :tooltip="tooltip"
							 :crosshair="crosshair"
							 :axes="axes"
							 :palettes="palettes"
							 :tooltipRender="tooltipRender"
							 :legendSettings="legendSettings || { visible: false }"
							 v-if="display">
			<e-series-collection>
				<!-- CoF -->
				<e-series :dataSource="roadChartData"
									type="Scatter"
									xName="chartDate"
									yName="SurfaceGrip"
									:enableTooltip="true"
									yAxisName="yAxisSurfaceGrip"
									tooltipMappingName="chartDate"
									tooltipFormat="<b>CoF: ${point.y}</b>" />
				<!-- Wind Speed -->
				<e-series :dataSource="roadChartData"
									type="Scatter"
									xName="chartDate"
									yName="WindSpeed"
									:enableTooltip="true"
									yAxisName="yAxisWindSpeed"
									tooltipMappingName="WindDirection"
									:tooltipFormat="windSpeedTooltip" />

				<!-- Precipitation Rate -->
				<template v-if="hasSnowRate">
					<e-series :dataSource="roadChartData"
										type="SplineArea"
										xName="chartDate"
										yName="SnowRate"
										name="Snow Rate"
										yAxisName="yAxisPrecipRate"
										tooltipMappingName="chartDate" />
				</template>
				<template v-if="hasRainRate">
					<e-series :dataSource="roadChartData"
										type="SplineArea"
										xName="chartDate"
										yName="RainRate"
										name="Rain Rate"
										yAxisName="yAxisPrecipRate"
										tooltipMappingName="chartDate" />
				</template>
				<template v-if="hasMixedRate">
					<e-series :dataSource="roadChartData"
										type="SplineArea"
										xName="chartDate"
										yName="MixedRate"
										name="Mixed Rate"
										yAxisName="yAxisPrecipRate"
										tooltipMappingName="chartDate" />
				</template>

				<!-- Temperature -->
				<e-series :dataSource="chartData"
									type="Spline"
									splineType="Monotonic"
									xName="chartDate"
									yName="SurfaceTemp"
									name="Surface"
									width="3"
									yAxisName="yAxisTemperature"
									tooltipMappingName="chartDate" />
				<e-series :dataSource="chartData"
									type="Spline"
									splineType="Monotonic"
									xName="chartDate"
									yName="AirTemp"
									name="Air"
									width="3"
									yAxisName="yAxisTemperature"
									tooltipMappingName="chartDate" />

				<!-- Road Condition -->
				<e-series v-if="show.dry"
									:dataSource="dry.chart"
									type="StackingColumn"
									name="Road: Dry"
									xName="chartDate"
									yName="value"
									yAxisName="yAxisRoadCondition"
									tooltipMappingName="chartDate"
									tooltipFormat="<b>Dry</b>"
									:columnWidth="roadConditionWidth"
									:columnSpacing="0" />
				<e-series v-if="show.wet"
									:dataSource="wet.chart"
									type="StackingColumn"
									name="Road: Wet"
									xName="chartDate"
									yName="value"
									yAxisName="yAxisRoadCondition"
									tooltipMappingName="chartDate"
									tooltipFormat="<b>Wet</b>"
									:columnWidth="roadConditionWidth"
									:columnSpacing="0" />
				<e-series v-if="show.slush"
									:dataSource="slush.chart"
									type="StackingColumn"
									name="Road: Slush"
									xName="chartDate"
									yName="value"
									yAxisName="yAxisRoadCondition"
									tooltipMappingName="chartDate"
									tooltipFormat="<b>Slush</b>"
									:columnWidth="roadConditionWidth"
									:columnSpacing="0" />
				<e-series v-if="show.snow"
									:dataSource="snow.chart"
									type="StackingColumn"
									name="Road: Snow"
									xName="chartDate"
									yName="value"
									yAxisName="yAxisRoadCondition"
									tooltipMappingName="chartDate"
									tooltipFormat="<b>Snow</b>"
									:columnWidth="roadConditionWidth"
									:columnSpacing="0" />
				<e-series v-if="show.mixed"
									:dataSource="mixed.chart"
									type="StackingColumn"
									name="Road: Icy"
									xName="chartDate"
									yName="value"
									yAxisName="yAxisRoadCondition"
									tooltipMappingName="chartDate"
									tooltipFormat="<b>Icy</b>"
									:columnWidth="roadConditionWidth"
									:columnSpacing="0" />
				<e-series v-if="show.black"
									:dataSource="black.chart"
									type="StackingColumn"
									name="Road: Black Ice"
									xName="chartDate"
									yName="value"
									yAxisName="yAxisRoadCondition"
									tooltipMappingName="chartDate"
									tooltipFormat="<b>Black Ice</b>"
									:columnWidth="roadConditionWidth"
									:columnSpacing="0" />
			</e-series-collection>
		</ejs-chart>
	</div>
</template>

<script>
import {
	Tooltip,
	DateTime,
	Logarithmic,
	Crosshair,
	SplineSeries,
	LineSeries,
	StripLine,
	AreaSeries,
	SplineAreaSeries,
	ScatterSeries,
	AccumulationTooltip,
	StackingColumnSeries
} from "@syncfusion/ej2-vue-charts";
import { mapStores } from "pinia";
import moment from "moment-timezone";

import { formatChartDate, formatDecimal, formatWindDirection, isTabletScreen, parseRoadCondition, parseRoadConditionChart } from "../js/utils";
import { useBaseStore } from "../js/store";

export default {
	name: "sensor-chart-component",
	props: [
		"sensor",
		"chartHeight",
		"dataTransmissions",
		"roadConditionsHistory",
		"forecastData",
		'legendSettings'
	],
	provide: {
		chart: [
			LineSeries,
			Tooltip,
			DateTime,
			StripLine,
			SplineSeries,
			SplineAreaSeries,
			Logarithmic,
			Crosshair,
			AreaSeries,
			ScatterSeries,
			AccumulationTooltip,
			StackingColumnSeries,
		],
	},
	data: function () {
		return {
			chartMobileStyle: { 'width': '100%' },
			crosshair: { enable: true, lineType: "Vertical" },
			tooltip: {
				enable: true,
				shared: true, // This shows multiple data points in a single tooltip
				enableAnimation: false,
				header: "<b>${point.tooltip}</b>",
				format: "${series.name}: <b>${point.y}</b>",
			},
			show: {
				dry: false,
				wet: false,
				slush: false,
				mixed: false,
				snow: false,
				black: false,
			},
			display: false,
		};
	},
	methods: {
		tooltipRender(args) {
			if (args.data.pointY === 0) {
				args.cancel = true;
			}
		},
		mobileStyles() {
			const style = {
				// 'touch-action': 'unset !important',
			}
			this.chartMobileStyle = style;
		},
		displayChart(isVisible) {
			if (isVisible) {
				this.display = true;
			}
		},
	},
	computed: {
		...mapStores(useBaseStore),
		roadConditionWidth() {
			// Get the amount of time between the first two data points
			// This seems to be the most reliable way to determine the width of the columns
			// Its still imperfect
			const first = this.roadChartData[0];
			const second = this.roadChartData[1];
			if (!first || !second) return 1;
			const firstDate = moment(first.chartDate);
			const secondDate = moment(second.chartDate);
			const diff = secondDate.diff(firstDate, 'minutes');
			const width = 60 - diff > 0 ? 60 - diff : 1;
			return width;
		},
		isMobile() {
			return isTabletScreen(true)
		},
		tempUnit() {
			return this.sensor?.GroupEmbedded?.TemperatureUnits === "Celsius" ? "C" : "F";
		},
		palettes() {
			let palettes = ["transparent", "transparent",];

			if (this.hasSnowRate) {
				palettes.push("#d8d2f8");
			}
			if (this.hasRainRate) {
				palettes.push("#a6d8eb");
			}
			if (this.hasMixedRate) {
				palettes.push("#caf4f6");
			}

			palettes = [...palettes, "black", "#319cf4"];

			if (this.show.dry) {
				palettes.push("#666971");
			}
			if (this.show.wet) {
				palettes.push("#c2d689");
			}
			if (this.show.slush) {
				palettes.push("#c8efb6");
			}
			if (this.show.snow) {
				palettes.push("#fababa");
			}
			if (this.show.mixed) {
				palettes.push("#ffd597");
			}
			if (this.show.black) {
				palettes.push("#e98f8f");
			}

			return palettes;
		},
		primaryXAxis() {
			const now = moment()
			const nowPlusTen = moment().add(10, 'minutes')
			const startDate = formatChartDate(moment().startOf('day'), this.sensor.TimeZone)
			const endDate = formatChartDate(moment().startOf('day').add(10, 'minutes'), this.sensor.TimeZone)
			const firstMidnight = formatChartDate(moment().startOf('day').add(1, 'day'), this.sensor.TimeZone)
			const firstMidnightPlusTen = formatChartDate(moment().startOf('day').add(1, 'day').add(10, 'minutes'), this.sensor.TimeZone)
			const secondMidnight = formatChartDate(moment().startOf('day').add(2, 'day'), this.sensor.TimeZone)
			const secondMidnightPlusTen = formatChartDate(moment().startOf('day').add(2, 'day').add(10, 'minutes'), this.sensor.TimeZone)
			const secondMidnightText = moment().startOf('day').add(2, 'day').format("MMM D")
			const thirdMidnight = formatChartDate(moment().startOf('day').add(3, 'day'), this.sensor.TimeZone)
			const thirdMidnightPlusTen = formatChartDate(moment().startOf('day').add(3, 'day').add(10, 'minutes'), this.sensor.TimeZone)
			const thirdMidnightText = moment().startOf('day').add(3, 'day').format("MMM D")
			return {
				visible: true,
				color: "#5fb1f6",
				valueType: "DateTime",
				labelFormat: "h a",
				intervalType: "Hours",
				labelStyle: {
					size: "12px",
				},
				title: false,
				crosshairTooltip: { enable: false },
				majorGridLines: { color: "transparent" },
				majorTickLines: { color: "transparent" },
				stripLines: [
					{
						start: now,
						end: nowPlusTen,
						color: "#26a172",
						visible: true,
						sizeType: "Minutes",
						zIndex: "Over",
						rotation: 0,
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
					{
						start: startDate,
						end: endDate,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: "Today",
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},

					{
						start: firstMidnight,
						end: firstMidnightPlusTen,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: "Tomorrow",
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
					{
						start: secondMidnight,
						end: secondMidnightPlusTen,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: secondMidnightText,
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
					{
						start: thirdMidnight,
						end: thirdMidnightPlusTen,
						color: "#6290d4",
						visible: true,
						sizeType: "Minutes",
						text: thirdMidnightText,
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						verticalAlignment: "Start",
						horizontalAlignment: "Start",
						region: "Chart",
					},
				],
			};
		},
		axes() {
			if (!this.roadChartData) return [];
			const snowRate = this.roadChartData.map((r) => r.SnowRate);
			const rainRate = this.roadChartData.map((r) => r.RainRate);
			const mixedRate = this.roadChartData.map((r) => r.MixedRate);

			const surfaceTemps = this.chartData.map((r) => r.SurfaceTemp);
			const airTemps = this.chartData.map((r) => r.AirTemp);

			const rates = [...snowRate, ...rainRate, ...mixedRate].map((e) => {
				if (!e) e = 0;
				return e;
			});
			const temps = [...surfaceTemps, ...airTemps].map((e) => {
				if (!e) e = 0;
				return e;
			});

			const max = Math.max(...rates);

			let stripLines = [
				{
					start: this.sensor?.GroupEmbedded?.IsMetric ? 0.76 : 0.3,
					end: this.sensor?.GroupEmbedded?.IsMetric ? 0.760001 : 0.300001,
					color: "#6290d4",
					visible: true,
					text: "Heavy",
					zIndex: "Over",
					rotation: 0,
					textStyle: {
						size: 14,
						color: "#26a172",
					},
					horizontalAlignment: "End",
				},
			];

			if (max <= 1) {
				stripLines = [
					...stripLines,
					{
						start: this.sensor?.GroupEmbedded?.IsMetric ? 0.13 : 0.05,
						end: this.sensor?.GroupEmbedded?.IsMetric ? 0.1300001 : 0.05000001,
						color: "#6290d4",
						visible: true,
						text: "Light",
						zIndex: "Over",
						rotation: 0,
						textStyle: {
							size: 14,
							color: "#26a172",
						},
						horizontalAlignment: "End",
					},
				];
			}

			return [
				{
					visible: true,
					opposedPosition: true,
					labelFormat: `{value} ${this.precipRateUnit}`,
					name: "yAxisPrecipRate",
					labelStyle: {
						color: "transparent",
						size: 0,
					},
					roundPadding: "Additional",
					minimum: -0.03,
					maximum: max < 0.3 ? 0.5 : max + 0.1,
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
					stripLines: stripLines,
				},
				{
					visible: true,
					labelFormat: `{value}° ${this.tempUnit}`,
					name: "yAxisTemperature",
					title: false,
					roundPadding: "Additional",
					minimum: Math.trunc(Math.min(...temps) - 5),
					maximum: Math.trunc(Math.max(...temps) + 5),
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
				{
					visible: false,
					name: "yAxisRoadCondition",
					roundPadding: "Additional",
					minimum: 0,
					maximum: .5,
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
				{
					visible: false,
					name: "yAxisSurfaceGrip",
					roundPadding: "Additional",
					minimum: 0,
					maximum: 1,
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
				{
					visible: false,
					name: "yAxisWindSpeed",
					majorGridLines: { color: "transparent" },
					majorTickLines: { color: "transparent" },
				},
			];
		},
		chartData() {
			let transmissions = [...this.dataTransmissions, ...this.forecastData];
			this.roadConditionsHistory.forEach((rc) => {
				transmissions.push({
					...rc,
					AirTemp: null,
					SurfaceTemp: null,
				});
			});
			// Remove all chart data except 4 hours before now and 72 after now
			transmissions = transmissions.filter((e) => {
				const startDate = moment().subtract(4, 'hours')
				const chartLimit = this.baseStore.preferences.chartLimit || 72
				const endDate = moment().add(chartLimit, 'hours')
				return moment(e.TransmissionDateTimeUTC).utc(true).isBetween(startDate, endDate)
			})
			transmissions = transmissions.sort((a, b) => moment(a.TransmissionDateTimeUTC).diff(b.TransmissionDateTimeUTC))
			return transmissions.reduce((arr, e) => {
				if (e.AirTemp === null && e.SurfaceTemp === null) return arr;
				arr.push({
					chartDate: e.chartDate,
					AirTemp: e.AirTemp,
					SurfaceTemp: e.SurfaceTemp,
				});

				return arr;
			}, []);
		},
		roadChartData() {
			if (this.dataTransmissions && this.dataTransmissions.length > 0) {
				let transmissions = [...this.dataTransmissions, ...this.forecastData]
				this.roadConditionsHistory.forEach((rc) => {
					transmissions.push({
						...rc,
						AirTemp: null,
						SurfaceTemp: null,
					});
				});
				// Remove all chart data except 4 hours before now and 72 after now
				transmissions = transmissions.filter((e) => {
					const startDate = moment().subtract(4, 'hours')
					const chartLimit = this.baseStore.preferences.chartLimit || 72
					const endDate = moment().add(chartLimit, 'hours')
					return moment(e.TransmissionDateTimeUTC).utc(true).isBetween(startDate, endDate)
				})
				transmissions = transmissions.sort((a, b) => moment(a.TransmissionDateTimeUTC).diff(b.TransmissionDateTimeUTC));
				const ret = transmissions.reduce((acc, rc, i) => {
					const next = transmissions[i + 1];
					const prev = transmissions[i - 1];

					if (!rc.SnowRate) {
						if (next?.SnowRate > 0 || prev?.SnowRate > 0) rc.SnowRate = 0;
						else rc.SnowRate = null;
					}
					if (!rc.RainRate) {
						if (next?.RainRate > 0 || prev?.RainRate > 0) rc.RainRate = 0;
						else rc.RainRate = null;
					}
					if (!rc.MixedRate) {
						if (next?.MixedRate > 0 || prev?.MixedRate > 0) rc.MixedRate = 0;
						else rc.MixedRate = null;
					}

					rc.value = 0.03;
					rc.ParsedRoadCondition = parseRoadCondition(rc.RoadCondition);

					switch (rc.ParsedRoadCondition) {
						case 'dry':
							if (rc.value >= 0)
								this.show.dry = true;
							break;
						case 'wet':
							if (rc.value >= 0)
								this.show.wet = true;
							break;
						case 'slush':
							if (rc.value >= 0)
								this.show.slush = true;
							break;
						case 'snow':
							if (rc.value >= 0)
								this.show.snow = true;
							break;
						case 'mixed':
							if (rc.value >= 0)
								this.show.mixed = true;
							break;
						case 'black':
							if (rc.value >= 0)
								this.show.black = true;
							break;
					}

					acc.push({
						chartDate: rc.chartDate,

						// Precip Rate Data
						SnowRate: rc.SnowRate,
						RainRate: rc.RainRate,
						MixedRate: rc.MixedRate,

						// Road Condition Data
						SurfaceGrip: rc.SurfaceGrip,
						ParsedRoadCondition: rc.ParsedRoadCondition,
						value: rc.value,

						// Wind Speed
						WindSpeed: formatDecimal(rc.WindSpeed),
						WindDirection: formatWindDirection(rc.WindDirection)
					});

					return acc;
				}, []);
				return ret;
			} else {
				return [];
			}
		},
		// Precip 
		hasMixedRate() {
			return this.roadChartData.filter((e) => e.MixedRate > 0).length > 0;
		},
		hasSnowRate() {
			return this.roadChartData.filter((e) => e.SnowRate > 0).length > 0;
		},
		hasRainRate() {
			return this.roadChartData.filter((e) => e.RainRate > 0).length > 0;
		},
		// Road Conditions
		dry() {
			return parseRoadConditionChart(this.roadChartData, 'dry');
		},
		wet() {
			return parseRoadConditionChart(this.roadChartData, 'wet');
		},
		slush() {
			return parseRoadConditionChart(this.roadChartData, 'slush');
		},
		mixed() {
			return parseRoadConditionChart(this.roadChartData, 'mixed');
		},
		snow() {
			return parseRoadConditionChart(this.roadChartData, 'snow');
		},
		black() {
			return parseRoadConditionChart(this.roadChartData, 'black');
		},

		precipRateUnit() {
			return this.sensor.GroupEmbedded.IsMetric ? "cm/hr" : "in/hr"
		},
		windSpeedTooltip() {
			const unit = this.sensor.GroupEmbedded.IsMetric ? "km/hr" : "mph";
			return "<b>Wind: ${point.y} " + unit + " ${point.tooltip}</b>";
		}
	},
	created() {
		this.TimeZone = this.sensor.TimeZone;
	},
	mounted() {
		if (this.isMobile) {
			this.mobileStyles()
		}
	},
};
</script>
