<template>
  <div class="tool-cont">

    <ejs-tooltip class="tooltip-target"
                 :content="props.content">
      <div>
        <i class="fa fa-solid fa-info-circle"></i>
      </div>
    </ejs-tooltip>
    <ejs-button cssClass="tool-btn"
                is-primary="true"
                @click.native="triggerAction">
      <img :src="icon"
           :alt="label" />
      <div>
        <span v-for="label in labels"
              :key="label">{{ label }}<br /></span>
      </div>
    </ejs-button>
    <p>{{ props.content }}</p>
  </div>
</template>

<script setup>

import { defineProps } from 'vue';

const props = defineProps([
  'label',
  'content',
  'icon',
  'action',
])

const triggerAction = () => {
  props.action();
}

const labels = props.label.split(' ');

</script>

<style>
.e-tooltip-wrap .e-arrow-tip-inner:before {
  content: '' !important;
}

.tooltip-target {
  display: none;
}

div.tools-mobile {
  display: flex;
  margin-bottom: 15px;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

div.tools-mobile .tool-cont {
  height: 138px;
}

.tool-btn {
  display: flex !important;
  height: 100px;
  border-radius: 10px 0 0 10px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  flex: 0 0 105px;
}

.tool-cont {
  display: flex;
  height: 100px;
  position: relative;
  background-color: white;
  flex-direction: row;
  gap: 5px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px;
  margin-bottom: 10px;
  overflow: hidden;
}

.tool-cont p {
  display: inline-block;
  margin: 5px 2px 0px;
  font-size: 1rem;
  line-height: 1.1rem;
}

.tool-cont .tool-cont-body {
  text-align: center;
}

.tool-cont img {
  width: 40px;
  height: 40px;
}

.tool-cont .fa-info-circle {
  font-size: 20px;
  color: #fff;
}

.app-tool-show {
  display: none !important;
}

.sensor-opts-cont,
.sensor-details-cont {
  height: 230px;
}

@media (max-width: 1150px) {
  .tool-cont {
    flex-basis: 100px;
  }
}

@media (max-width: 800px) {

  .tooltip-target {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .tool-btn {
    flex-basis: 105px !important;
    border-radius: 10px !important;
  }

  .tool-cont p {
    display: none;
  }

}

@media (max-width: 450px) {
  .sensor-tools {
    flex-wrap: wrap;
  }

  .tool-cont {
    flex-basis: 49% !important;
  }

  .tool-btn {
    flex-basis: 100% !important;
  }
}
</style>